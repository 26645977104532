import * as React from "react";

export const Shield = (props) => (
  <svg
    width={121}
    height={121}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M55.962 12.251 16.864 28.36v39.703c0 21.276 43.636 41.594 43.636 41.594s43.636-20.318 43.636-41.594V28.36L65.037 12.251a11.797 11.797 0 0 0-9.075 0v0Z"
      stroke="#1F396C"
      strokeWidth={6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.784 48.551H41.241a2.647 2.647 0 0 0-2.647 2.647v28.485a2.647 2.647 0 0 0 2.647 2.647h38.543a2.647 2.647 0 0 0 2.647-2.647V51.198a2.647 2.647 0 0 0-2.647-2.647ZM46.005 48.551v-6.075a14.495 14.495 0 1 1 28.99 0v6.075"
      stroke="#1F396C"
      strokeWidth={6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.5 71.062a5.621 5.621 0 1 0 0-11.243 5.621 5.621 0 0 0 0 11.243Z"
      stroke="#1F396C"
      strokeWidth={6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
