import { faFile, faFilePdf, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import NewDoc from "../../components/admin/document/NewDoc";
import Layout from "../../components/Layout";
import { formatedNumber } from "../../js/parseNumber";
import { getCookie } from "../../_cookies";
import { ADM_API } from "../../_variables";

const Documentos = () => {
  const es = {
    title: "Documentos",
    searchByUser: "Buscar por usuario",
    userInput: "Usuarios",
    userOption: "Selecciona el usuario",
    searchByTransaction: "Buscar por transacción",
    transactionInput: "Transacciones",
    transactionOption: "Selecciona la transacción",
    deleteFileConfirmation:
      "¿Estás seguro de eliminar este documento? ¡Esta acción NO es REVERSIBLE!",
  };
  const en = {
    title: "Documents",
    searchByUser: "Search by user",
    userInput: "Users",
    userOption: "Choose user",
    searchByTransaction: "Search by transaction",
    transactionInput: "Transactions",
    transactionOption: "Choose transaction",
    deleteFileConfirmation:
      "Are you sure about delete this file? This action CAN'T be UNDONE!",
  };

  const [txt, setTxt] = useState(es);
  const [users, setUsers] = useState([]);
  const [transacciones, setTransacciones] = useState([]);
  const [docs, setDocs] = useState([]);
  const [st, setSt] = useState(false);
  const [userToSearch, setUserToSearch] = useState("");
  const [transToSearch, setTransToSearch] = useState("");

  const getUsersList = () => {
    const formData = new FormData();
    formData.append("cookie", getCookie());

    axios
      .post(`${ADM_API}/get_Customers`, formData)
      .then((res) => {
        if (res.status === 200 && res.data.status === "ok") {
          setUsers(res.data.result);
        }
      })
      .catch(() => {});
  };

  const obtenerTransacciones = () => {
    const formData = new FormData();
    formData.append("cookie", getCookie());

    axios
      .post(`${ADM_API}/get_Transactions`, formData)
      .then((res) => {
        if (res.status === 200 && res.data.status === "ok") {
          setTransacciones(res.data.result);
        }
      })
      .catch(() => {});
  };

  const searchByUser = () => {
    if (userToSearch) {
      const params = new FormData();
      params.append("cookie", getCookie());
      params.append("ide", userToSearch);

      axios
        .post(`${ADM_API}/get_Files_from_User`, params)
        .then((res) => {
          if (res.status === 200 && res.data.status === "ok") {
            // setUserToSearch(userToSearch)
            setDocs(res.data.result);
            setSt(false);
          }
        })
        .catch(() => {});
    }
  };

  const searchByTrans = () => {
    if (transToSearch) {
      const params = new FormData();
      params.append("cookie", getCookie());
      params.append("ide", transToSearch);

      axios
        .post(`${ADM_API}/get_Files_from_Transaction`, params)
        .then((res) => {
          if (res.status === 200 && res.data.status === "ok") {
            setDocs(res.data.result);
            setSt(true);
          }
        })
        .catch(() => {});
    }
  };

  const deleteFileByTrans = (fileToDelete) => {
    if (window.confirm(txt.deleteFileConfirmation)) {
      if (transToSearch) {
        const params = new FormData();
        params.append("cookie", getCookie());
        params.append("ide", transToSearch);
        params.append("filename", fileToDelete);

        axios
          .post(`${ADM_API}/delete_Files_from_Transaction`, params)
          .then((res) => {
            window.location.reload();
            return;
          })
          .catch((err) => {
            window.location.reload();
            return;
          });
      }
    }
  };
  const deleteFileByUser = (fileToDelete) => {
    if (window.confirm(txt.deleteFileConfirmation)) {
      if (userToSearch) {
        const params = new FormData();
        params.append("cookie", getCookie());
        params.append("ide", userToSearch);
        params.append("filename", fileToDelete);

        axios
          .post(`${ADM_API}/delete_Files_from_User`, params)
          .then((res) => {
            window.location.reload();
            return;
          })
          .catch((err) => {
            window.location.reload();
            return;
          });
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }

    getUsersList();
    obtenerTransacciones();
  }, []);

  return (
    <Layout>
      {!getCookie() ? (
        <Navigate to="/login" replace />
      ) : (
        <div className="container py-5">
          <div className="d-flex justify-content-between mb-5">
            <h1 className="text-primary fw-bold">{txt.title}</h1>
            {users.length > 0 && transacciones.length > 0 && (
              <NewDoc users={users} trans={transacciones} />
            )}
          </div>

          <div className="row">
            <div className="col-12 d-flex mb-3">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="docsByUser"
                  aria-label="Floating label select example"
                  defaultValue="DEFAULT"
                  onChange={(e) => {
                    setUserToSearch(e.target.value);
                  }}
                >
                  <option value="DEFAULT" disabled>
                    {txt.userOption}
                  </option>
                  {users &&
                    users.map((usr) => (
                      <option value={usr.id} key={usr.id}>
                        {usr.id} - {usr.name}
                      </option>
                    ))}
                </select>
                <label htmlFor="docsByUser">{txt.userInput}</label>
              </div>
              <button
                className="btn btn-secondary ms-3"
                onClick={() => searchByUser()}
              >
                {txt.searchByUser}
              </button>
            </div>
            <div className="col-12 d-flex mb-3">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="docsByTrans"
                  aria-label="Floating label select example"
                  defaultValue="DEFAULT"
                  onChange={(e) => setTransToSearch(e.target.value)}
                >
                  <option value="DEFAULT" disabled>
                    {txt.transactionOption}
                  </option>
                  {transacciones &&
                    transacciones.map((tra) => (
                      <option value={tra.id} key={tra.id}>
                        {tra.id} - {tra.client_id} -{" "}
                        {formatedNumber(tra.account_balance)}
                      </option>
                    ))}
                </select>
                <label htmlFor="docsByTrans">{txt.transactionInput}</label>
              </div>
              <button
                className="btn btn-secondary ms-3"
                onClick={() => searchByTrans()}
              >
                {txt.searchByTransaction}
              </button>
            </div>
          </div>
          <div className="row mt-5 border rounded mx-3 p-3">
            {docs.length > 0 &&
              docs.map((doc, i) => (
                <div
                  className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 p-3 text-center overflow-hidden p-2"
                  key={`${i}-${doc}`}
                >
                  <div className="w-100 h-100 border rounded overflow-hidden">
                    <div className="d-flex justify-content-end">
                      <button
                        className="btn text-danger mb-3"
                        onClick={
                          st
                            ? () => {
                                deleteFileByTrans(doc);
                              }
                            : () => {
                                deleteFileByUser(doc);
                              }
                        }
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                    <a
                      href={
                        st
                          ? `https://api.mwtitlesagency.com/assets/files/transfiles/${transToSearch}/${doc}`
                          : `https://api.mwtitlesagency.com/assets/files/userfiles/${userToSearch}/${doc}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-decoration-none text-reset text-center"
                    >
                      <FontAwesomeIcon
                        icon={faFile}
                        size="4x"
                        className="text-secondary mb-2"
                      />{" "}
                      <br />
                      {doc}
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Documentos;
