import { useEffect, useState } from "react";
import axios from "axios";
import "../../../css/docsCarousel.css";
import { getCookie } from "../../../_cookies";
import { API_URL } from "../../../_variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import UploadFile from "../files/UploadFile";
import { unixToLocaleString, timeStamp, timeConverter } from "../../../js";

const UserDocs = ({ userId }) => {
  const es = {
    name: "Nombre",
    date: "Fecha",
    document: "Documento",
    upload: "Subir",
    filterName: "Por nombre",
    filterDate: "Por fecha",
    filterFileName: "Filtrar por nombre de archivo...",
  };
  const en = {
    name: "Name",
    date: "Date",
    document: "Document",
    upload: "Upload",
    filterName: "By name",
    filterDate: "By date",
    filterFileName: "Filter by file name...",
  };

  const [docs, setDocs] = useState([]);
  const [data, setData] = useState([]);
  const [txt, setTxt] = useState(es);
  //Estado para los buscadores
  const [search, setSearch] = useState("");
  const [porFecha, setPorFecha] = useState(false);
  const [porNombre, setPorNombre] = useState(false);

  const getMyFiles = async () => {
    const params = new FormData();
    params.append("cookie", getCookie());

    const res = await axios.post(`${API_URL}/get_my_Files`, params);
    setDocs(res.data.result);
  };
  useEffect(() => {
    setData(docs.filter((doc) => doc.filename !== "main.png"));
  }, [docs]);

  useEffect(() => {
    getMyFiles();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }
  }, []);

  const handlePorFecha = (e) => {
    setPorFecha(!porFecha);
  };

  const handlePorNombre = (e) => {
    setPorNombre(!porNombre);
  };

  //Funcion buscador
  const handleSearch = (e) => {
    setSearch(e.target.value);
    console.log(search);
  };
  let results = [];
  // Filtrado por texto
  if (search !== "") {
    if (search) {
      results = data.filter((dato) =>
        dato.filename.toLowerCase().includes(search.toLowerCase())
      );
    } else {
      results = data;
    }
  } else {
    //Filtrado por fechas
    console.log(results);
    if (porFecha) {
      results = data.sort((a, b) => {
        return b.date_modified - a.date_modified;
      });
    } else {
      results = data.sort((a, b) => {
        return a.date_modified - b.date_modified;
      });
    }
    //Filtrado por nombre
    console.log(results);
    if (porNombre) {
      results = data.sort((a, b) => {
        return a.filename.localeCompare(b.filename);
      });
    } else {
      results = data.sort((a, b) => {
        results = data;
      });
    }
  }

  return (
    <>
      {docs && (
        <div className="horizontal-scroll rounded-3 border shadow-sm p-4">
          <div>
            <UploadFile /> {txt.upload}
          </div>
          <div className="d-flex justify-content-end pt-3">
            <div
              className="btn btn-outline-primary me-3"
              onClick={handlePorFecha}
            >
              {txt.filterDate} 🡫🡩
            </div>
            <div
              className="btn btn-outline-primary me-3"
              onClick={handlePorNombre}
            >
              {txt.filterName} 🡫🡩
            </div>
          </div>
          <div className="d-flex flex-column mt-3 flex-md-row">
            <input
              type="text"
              className="border py-1 border-primary rounded-3 mb-3 me-3"
              placeholder="Try search something..."
              value={search}
              onChange={handleSearch}
            />
          </div>
          <div>
            <table className="table table-striped align-middle text-center">
              <thead>
                <tr className="text-center">
                  <th scope="col">{txt.name} </th>
                  <th scope="col">{txt.date} </th>
                  <th scope="col">{txt.document} </th>
                </tr>
              </thead>
              <tbody>
                {results.map((doc, i) => {
                  return (
                    <tr key={`${i}-${doc.filename}`}>
                      <th className="fw-normal">
                        <p>{doc.filename}</p>
                      </th>
                      <th className="fw-normal">
                        <p>{timeConverter(doc.date_modified)}</p>
                      </th>
                      <th>
                        <a
                          href={`https://api.mwtitlesagency.com/assets/files/userfiles/${userId}/${doc.filename}`}
                          target="_blank"
                          key={`${i}-${doc.filename}`}
                          rel="noopener noreferrer"
                          className="text-decoration-none text-reset"
                        >
                          <div className="d-inline-block text-center me-3 border rounded p-3">
                            <FontAwesomeIcon
                              icon={faFileAlt}
                              size="4x"
                              className="text-danger mb-3"
                            />{" "}
                            <br />
                          </div>
                        </a>
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default UserDocs;
