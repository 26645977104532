import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import FileBase64 from "react-file-base64";
import { getCookie } from "../../../_cookies";
import { API_URL } from "../../../_variables";
import LoadingSm from "../../LoadingSm";

const UpdateMyInfo = ({
  name,
  email,
  address,
  bank,
  accountNumber,
  routingNumber,
  swiftCode,
  id,
}) => {
  const es = {
    btnTitle: "Mi información",
    modTitle: "Actualizar mi información",
    modCancel: "Cancelar",
    modSuccess: "Guardar cambios",
    updtName: "Nombre completo",
    updtEmail: "Correo electrónico",
    updtAddress: "Dirección",
    updtCity: "Ciudad y Código Postal",
    updtBank: "Información bancaria",
    updtAccNum: "Número de cuenta",
    updtRoutNum: "Número de routing",
    updtSwiftCode: "Código Swift",
    updtPicture: "Cambiar foto de perfil",
    alertPicture:
      "Foto de perfil actualizada correctamente, es posible que tarde unos minutos en reflejarse.",
  };
  const en = {
    btnTitle: "My information",
    modTitle: "Update my information",
    modCancel: "Cancel",
    modSuccess: "Save changes",
    updtName: "Full Name",
    updtEmail: "Email",
    updtAddress: "Address",
    updtCity: "City & Zip Line",
    updtBank: "Bank information",
    updtAccNum: "Account number",
    updtRoutNum: "Routing number",
    updtSwiftCode: "Swift Code",
    updtPicture: "Change profile picture",
    alertPicture:
      "Profile photo updated, it could take a few minutes to see the new picture.",
  };

  const [txt, setTxt] = useState(es);
  const [loading, setLoading] = useState(false);
  const [updtName, setUpdtName] = useState(name);
  const [updtEmail, setUpdtEmail] = useState(email);
  const [updtBank, setUpdtBank] = useState(bank);
  const [updtAccountNumber, setUpdtAccountNumber] = useState(accountNumber);
  const [updtRoutingNumber, setUpdtRoutingNumber] = useState(routingNumber);
  const [updtSwiftCode, setUpdtSwiftCode] = useState(swiftCode);
  const [updtAddress, setUpdtAddress] = useState("");
  const [updtCity, setUpdtCity] = useState("");
  const [updtImage, setUpdtImage] = useState("");

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setTxt(en);
    } else {
      setTxt(es);
    }

    if (address) {
      setUpdtAddress(address.split("-", 2)[0]);
      setUpdtCity(address.split("-", 2)[1]);
    }
  }, []);

  const updateProfilePicture = (e) => {
    let divisiones = e.base64.split(",", 3);
    setUpdtImage(divisiones[1]);
  };

  const handleUpdate = () => {
    setLoading(true);

    if (updtImage) {
      const params = new FormData();
      params.append("cookie", getCookie());
      params.append("image", updtImage);

      axios
        .post(`${API_URL}/update_my_photo`, params)
        .then((res) => {
          if (res.data.status === "ok") {
            alert(txt.alertPicture);
          }
          updateInfo();
        })
        .catch(() => {
          updateInfo();
        });
    } else {
      updateInfo();
    }
  };

  const updateInfo = () => {
    const params = new FormData();
    params.append("cookie", getCookie());
    params.append("address", `${updtAddress}-${updtCity}`);
    params.append("bank_inf", updtBank);
    params.append("acc_number", updtAccountNumber);
    params.append("routing_num", updtRoutingNumber);
    params.append("swift_code", updtSwiftCode);

    axios
      .post(`${API_URL}/update_my_data`, params)
      .then(() => {
        setLoading(false);
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
        window.location.reload();
      });
  };

  return (
    <>
      <button
        className="btn btn-link text-reset text-decoration-none"
        data-bs-toggle="modal"
        data-bs-target="#updateMyInfoModal"
      >
        <h3>
          {txt.btnTitle} <FontAwesomeIcon icon={faPen} />
        </h3>
      </button>

      <div
        className="modal fade"
        id="updateMyInfoModal"
        tabIndex="-1"
        aria-labelledby="updateMyInfoModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="updateMyInfoModalLabel">
                {txt.modTitle}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row mb-3">
                <div className="col-12">
                  <img
                    src={
                      updtImage
                        ? updtImage
                        : `https://api.mwtitlesagency.com/assets/files/userfiles/${id}/main.png`
                    }
                    alt="Profile"
                    className="img-fluid"
                  />{" "}
                  <br />
                  <p className="mb-0 mt-3">{txt.updtPicture}</p>
                  <FileBase64 onDone={(e) => updateProfilePicture(e)} />
                </div>
              </div>
              <div className="row">
                {/* Nombre completo */}
                <div className="col-12 mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      id="updtName"
                      className="form-control"
                      value={updtName}
                      disabled
                      onChange={(e) => setUpdtName(e.target.value)}
                      placeholder={txt.updtName}
                    />
                    <label htmlFor="updtName">{txt.updtName}</label>
                  </div>
                </div>

                {/* Correo electrónico */}
                <div className="col-12 mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      id="updtEmail"
                      className="form-control"
                      value={updtEmail}
                      disabled
                      onChange={(e) => setUpdtEmail(e.target.value)}
                      placeholder={txt.updtEmail}
                    />
                    <label htmlFor="updtEmail">{txt.updtEmail}</label>
                  </div>
                </div>

                {/* Calle */}
                <div className="col-12 mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      id="updtStreet"
                      className="form-control"
                      value={updtAddress}
                      onChange={(e) => setUpdtAddress(e.target.value)}
                      placeholder={txt.updtAddress}
                    />
                    <label htmlFor="updtStreet">{txt.updtAddress}</label>
                  </div>
                </div>

                {/* Ciudad y Código Postal */}
                <div className="col-12 mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      id="updtCity"
                      className="form-control"
                      value={updtCity}
                      onChange={(e) => setUpdtCity(e.target.value)}
                      placeholder={txt.updtCity}
                    />
                    <label htmlFor="updtCity">{txt.updtCity}</label>
                  </div>
                </div>

                {/* Información bancaria */}
                <div className="col-12 mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      id="updtBank"
                      className="form-control"
                      value={updtBank}
                      onChange={(e) => setUpdtBank(e.target.value)}
                      placeholder={txt.updtBank}
                    />
                    <label htmlFor="updtBank">{txt.updtBank}</label>
                  </div>
                </div>

                {/* Número de cuenta */}
                <div className="col-12 mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      id="updtAccNum"
                      className="form-control"
                      value={updtAccountNumber}
                      onChange={(e) => setUpdtAccountNumber(e.target.value)}
                      placeholder={txt.updtAccNum}
                    />
                    <label htmlFor="updtAccNum">{txt.updtAccNum}</label>
                  </div>
                </div>

                {/* Número de routing */}
                <div className="col-12 mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      id="updtRoutNum"
                      className="form-control"
                      value={updtRoutingNumber}
                      onChange={(e) => setUpdtRoutingNumber(e.target.value)}
                      placeholder={txt.updtRoutNum}
                    />
                    <label htmlFor="updtRoutNum">{txt.updtRoutNum}</label>
                  </div>
                </div>

                {/* Código Swift */}
                <div className="col-12 mb-3">
                  <div className="form-floating">
                    <input
                      type="text"
                      id="updtSwiftCode"
                      className="form-control"
                      value={updtSwiftCode}
                      onChange={(e) => setUpdtSwiftCode(e.target.value)}
                      placeholder={txt.updtSwiftCode}
                    />
                    <label htmlFor="updtSwiftCode">{txt.updtSwiftCode}</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {txt.modCancel}
              </button>
              <button
                type="button"
                className="btn btn-primary text-white"
                onClick={() => handleUpdate()}
              >
                {loading ? <LoadingSm /> : txt.modSuccess}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateMyInfo;
