import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Shield } from "../assets/svgs";

export const PolicyPrivacy = () => {
  const [lang, setLang] = useState("es");

  useEffect(() => {
    if (localStorage.getItem("lang") === "en") {
      setLang("en");
    } else {
      setLang("es");
    }
  }, [lang]);

  return (
    <Layout>
      <div className="container py-5">
        <div className="row">
          <div className="col-12 d-flex align-items-center justify-content-center mb-4 w-full">
            <Shield className="mr-3" />
            <div className=""></div>
          </div>
        </div>
        {lang === "es" ? (
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-column align-items-center mb-4  justify-content-center">
                <h1 className="h4">Política de Privacidad</h1>
                <h1 className="h4">MIDWEST TITLE AGENCY</h1>
              </div>
              <p>
                En Midwest Title Agency, valoramos la privacidad y protección de
                los datos personales de nuestros clientes. Esta Política de
                Privacidad explica cómo recopilamos, usamos y protegemos la
                información personal que obtenemos a través de nuestro sitio
                web.
              </p>
              <p className="text-primary fw-bold">
                Recopilación de información personal
              </p>
              <p>
                Podemos recopilar información personal de diferentes maneras,
                incluyendo:
              </p>
              <ul>
                <li>
                  Información que nos proporciona directamente, como su nombre,
                  dirección de correo electrónico, número de teléfono, dirección
                  postal y otra información que pueda ser necesaria para
                  procesar una solicitud o una transacción.
                </li>
                <li>
                  Información que nos proporciona directamente, como su nombre,
                  dirección de correo electrónico, número de teléfono, dirección
                  postal y otra información que pueda ser necesaria para
                  procesar una solicitud o una transacción.
                </li>
              </ul>
              <p className="text-primary fw-bold">
                Uso de la información personal
              </p>
              <p>
                Utilizamos la información personal que recopilamos para los
                siguientes fines:
              </p>
              <ul>
                <li>
                  Procesar solicitudes y transacciones en nombre de nuestros
                  clientes.
                </li>
                <li>
                  Proporcionar información y servicios solicitados por los
                  clientes.
                </li>
                <li>
                  Personalizar la experiencia del usuario en nuestro sitio web.
                </li>
                <li>Mejorar nuestros productos y servicios.</li>
                <li>
                  Enviar información de marketing y promociones a nuestros
                  clientes.
                </li>
                <li>
                  Cumplir con nuestras obligaciones legales y reglamentarias.
                </li>
              </ul>
              <p className="text-primary fw-bold">
                Divulgación de información personal
              </p>
              <p>
                Podemos divulgar información personal a terceros en las
                siguientes circunstancias:
              </p>
              <ul>
                <li>
                  A empresas que nos prestan servicios relacionados con nuestros
                  productos y servicios, como procesamiento de transacciones,
                  marketing, análisis y detección de fraudes.
                </li>
                <li>A otras empresas de nuestro grupo empresarial.</li>
                <li>
                  A autoridades gubernamentales y reguladoras en cumplimiento de
                  obligaciones legales y reglamentarias.
                </li>
              </ul>
              <p className="text-primary fw-bold">
                Seguridad de la información personal
              </p>
              <p>
                Implementamos medidas de seguridad para proteger la información
                personal que recopilamos y mantenemos. Estas medidas incluyen:
              </p>
              <ul>
                <li>
                  Implementamos medidas de seguridad para proteger la
                  información personal que recopilamos y mantenemos. Estas
                  medidas incluyen:
                </li>
                <li>
                  Almacenamiento seguro de información personal en servidores
                  protegidos por contraseñas y cortafuegos.
                </li>
                <li>
                  Protección de transacciones en línea a través de la tecnología
                  de cifrado.
                </li>
              </ul>
              <p className="text-primary fw-bold">
                Protección de transacciones en línea a través de la tecnología
                de cifrado.
              </p>
              <p>
                Los titulares de datos tienen ciertos derechos en relación con
                la información personal que recopilamos. Estos derechos incluyen
                el derecho a acceder, corregir y eliminar la información
                personal que mantenemos. También pueden objetar o restringir el
                uso de su información personal en ciertas circunstancias.
              </p>
            </div>
            <p className="text-primary fw-bold">Contacto</p>
            <p>
              Si tiene alguna pregunta o inquietud sobre nuestra Política de
              Privacidad o el tratamiento de su información personal, no dude en
              contactarnos a través de los medios proporcionados en nuestro
              sitio web.
            </p>
            <p className="text-primary fw-bold">
              Cambios en la política de privacidad
            </p>
            <p>
              Podemos actualizar esta Política de Privacidad periódicamente para
              reflejar los cambios en nuestras prácticas de privacidad. Le
              recomendamos que revise regularmente esta política para estar
              informado sobre cómo protegemos la información personal de
              nuestros clientes.
            </p>
          </div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-column align-items-center mb-4  justify-content-center">
                <h1 className="h4">Privacy Policy</h1>
                <h1 className="h4">MIDWEST TITLE AGENCY</h1>
              </div>
              <p>
                At Midwest Title Agency, we value the privacy and protection of
                our customers' personal data. This Privacy Policy explains how
                we collect, use, and protect personal information we obtain
                through our website.
              </p>
              <p className="text-primary fw-bold">
                Collection of personal information
              </p>
              <p>
                We may collect personal information in various ways, including:
              </p>
              <ul>
                <li>
                  Information you provide directly, such as your name, email
                  address, phone number, mailing address, and other information
                  that may be necessary to process a request or transaction.
                </li>
                <li>
                  Information we automatically collect, such as your IP address,
                  browser type, operating system, date and time of visit, and
                  other technical information that helps us improve the user
                  experience on our website.
                </li>
              </ul>
              <p className="text-primary fw-bold">
                Use of personal information
              </p>
              <p>
                We use the personal information we collect for the following
                purposes:
              </p>
              <ul>
                <li>
                  Processing requests and transactions on behalf of our
                  customers.
                </li>
                <li>Personalizing the user experience on our website.</li>
                <li>Improving our products and services.</li>
                <li>
                  Sending marketing information and promotions to our customers.
                </li>
                <li>Complying with our legal and regulatory obligations.</li>
              </ul>
              <p className="text-primary fw-bold">
                Disclosure of personal information
              </p>
              <p>Disclosure of personal information</p>
              <ul>
                <li>
                  To companies that provide services related to our products and
                  services, such as transaction processing, marketing,
                  analytics, and fraud detection.
                </li>
                <li>To other companies within our corporate group.</li>
                <li>
                  To government and regulatory authorities in compliance with
                  legal and regulatory obligations.
                </li>
              </ul>
              <p className="text-primary fw-bold">
                Security of personal information
              </p>
              <p>
                We implement security measures to protect the personal
                information we collect and maintain. These measures include:
              </p>
              <ul>
                <li>
                  Restricted access to personal information to those employees
                  who need access to fulfill their job duties.
                </li>
                <li>
                  Secure storage of personal information on servers protected by
                  passwords and firewalls.
                </li>
                <li>
                  Protection of online transactions through encryption
                  technology.
                </li>
              </ul>
              <p className="text-primary fw-bold">Data subject rights</p>
              <p>
                Data subjects have certain rights in relation to the personal
                information we collect. These rights include the right to
                access, correct, and delete personal information we hold. They
                may also object to or restrict the use of their personal
                information under certain circumstances.
              </p>
            </div>
            <p className="text-primary fw-bold">Contact</p>
            <p>
              If you have any questions or concerns about our Privacy Policy or
              the treatment of your personal information, please do not hesitate
              to contact us through the means provided on our website.
            </p>
            <p className="text-primary fw-bold">
              Changes to the privacy policy
            </p>
            <p>
              We may update this Privacy Policy periodically to reflect changes
              in our privacy practices. We recommend that you review this policy
              regularly to stay informed about how we protect our customers'
              personal information.
            </p>
          </div>
        )}
        <p className="text-primary fw-bold">Last updated: [27/02/2023]</p>
      </div>
    </Layout>
  );
};
